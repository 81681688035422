import { reactive } from "vue";

export const store = reactive({
  configs: {},
  prices: {
    amendment_price: "15.98",
    bound_price: "15.99",
    deluxe_price: "7.99",
    midYearPrice: "17.99",
    mid_year_pro_support_price: "49.99",
    price: "14.99",
    printed_price: "7.97",
    prior_year_amendment_price: "17.97",
    prior_year_price_2016: "17.99",
    prior_year_price_2017: "17.99",
    prior_year_price_2018: "17.99",
    prior_year_price_2019: "17.99",
    prior_year_price_2020: "17.99",
    prior_year_price_2021: "17.99",
    prior_year_price_2022: "17.99",
    priority_support_price: "7.98",
    pro_support_price: "39.99",
    audit_defense_price: "19.99",
    federal_refund_transfer_price: "24.99",
    midYearPriceEffectiveDate: "2025-01-01 00:00:00",
    mid_year_pro_support_effective_date: "2024-03-01 00:00:00",
  },
  captcha: "",
});
