const appYear = process.env.VUE_APP_TAX_YEAR;
const generateString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const thCookie = () => {
  const currentDate = new Date();
  const date = new Date();
  const cookieSetDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDay() +
    "/" +
    date.getHours() +
    "-" +
    date.getMinutes() +
    "-" +
    date.getSeconds();
  const daysBeforeExpiration = 180; //6 months
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  let cookieName = "TAXES" + appYear;
  let cookieString;
  let expires;
  let isCommissionJunction = false;
  let machineId;
  let advertiser = "";
  let positionFiller1 = "";
  let positionFiller2 = "";
  let positionFiller3 = "";
  let isAdvertiser = false;

  // set cookie expiration
  currentDate.setTime(
    currentDate.getTime() + daysBeforeExpiration * 24 * 60 * 60 * 1000,
  );
  expires = "expires=" + currentDate.toUTCString();

  // get machine id
  machineId = generateString(24);
  machineId += date.getTime();

  // check for affiliate advertisers
  if (
    params.has("AID") ||
    params.has("PID") ||
    params.has("SID") ||
    params.has("aid") ||
    params.has("pid") ||
    params.has("sid")
  ) {
    isCommissionJunction = true;
  }

  // get advertiser
  if (isCommissionJunction) {
    if (params.get("AID") || params.get("PID") || params.get("SID")) {
      advertiser = params.get("AID") + "---" + params.get("PID");
      if (params.has("SID")) {
        advertiser += "---" + params.get("SID");
      }
    } else if (params.get("aid") || params.get("pid") || params.get("sid")) {
      advertiser = params.get("aid") + "---" + params.get("pid");
      if (params.has("sid")) {
        advertiser += "---" + params.get("sid");
      }
    }
    isAdvertiser = true;
  } else if (params.has("utm_source")) {
    isAdvertiser = true;
    advertiser = params.get("utm_source");
  }

  //set thCookie
  cookieString =
    machineId +
    "|" +
    advertiser +
    "|" +
    cookieSetDate +
    "|" +
    positionFiller1 +
    "|" +
    positionFiller2 +
    "|" +
    positionFiller3 +
    "|" +
    isAdvertiser +
    "|";
  document.cookie =
    cookieName + "=" + cookieString + ";" + expires + ";path=/;secure";

  // set refPage
  if (!sessionStorage.getItem("refPage")) {
    if (document.referrer) {
      sessionStorage.setItem("refPage", document.referrer);
    } else {
      let refPage = location.href.split("?")[0];
      sessionStorage.setItem("refPage", refPage);
    }
  }
};

export default thCookie;
