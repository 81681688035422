<template>
  <nav class="navbar navbar-expand-lg fixed-top">
    <div
      class="container-fluid navigation-content justify-content-normal justify-content-lg-between"
    >
      <div class="d-flex align-items-center">
        <MobileMenuToggle
          v-if="store.configs.isFreeFileActive && isCurrentFreeFileYear"
        />

        <transition duration="0">
          <router-link
            id="firstTabIndex"
            class="navbar-brand no-outline"
            to="/freefile2023"
            @click="closeMobileMenu"
          >
            <img src="@/assets/img/logo.svg" alt="Go to FreeTaxUSA home page" />
          </router-link>
        </transition>
      </div>
      <div
        v-if="store.configs.isFreeFileActive && isCurrentFreeFileYear"
        class="d-flex align-items-center"
      >
        <div
          class="collapse navbar-collapse pb-4 pb-md-0"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                href="https://www.irs.gov/freefile"
                target="_blank"
              >
                Return to Free File
              </a>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                @click="removeFocus"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Support
              </a>
              <ul class="dropdown-menu animate expand shadow">
                <li>
                  <router-link class="dropdown-item" to="/freefile2023/support"
                    >Customer Support</router-link
                  >
                </li>
                <li>
                  <a class="dropdown-item" :href="constants.contactSupportUrl"
                    >My Support Issues</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                @click="removeFocus"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                After You File
              </a>
              <ul class="dropdown-menu animate expand shadow">
                <li>
                  <a class="dropdown-item" :href="constants.checkUrl"
                    >Check E-File Status</a
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/freefile2023/refund-status"
                    >Where's My Refund?</router-link
                  >
                </li>
                <li>
                  <a class="dropdown-item" :href="constants.printUrl"
                    >Print / Download My Return</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="store.configs.isFreeFileActive && isCurrentFreeFileYear"
        class="d-flex"
      >
        <a class="btn sign-in" :href="constants.continueUrl">Sign In</a>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { onMounted, watch } from "vue";
import MobileMenuToggle from "@/components/MobileMenuToggle";
import menuMobileToggle from "@/functions/menuHandlerMobile";
import menuToggle from "@/functions/menuHandler";
import { constants } from "@/functions/constants-freefile";
import { store } from "@/functions/store";
import { ref } from "vue";
import { useRoute } from "vue-router";

const { closeMobileMenu } = menuMobileToggle();
const { closeMenu, removeFocus } = menuToggle();
const isCurrentFreeFileYear = ref();
const route = useRoute();
const path = ref(location.pathname);
isCurrentFreeFileYear.value = path.value.includes("freefile2023");

watch(route, () => {
  path.value = location.pathname;
  isCurrentFreeFileYear.value = path.value.includes("freefile2023");
});

onMounted(() => {
  window.addEventListener("scroll", closeMenu);
});
</script>

<style lang="scss" scoped>
.navbar {
  background-color: $white;
  min-height: 78px;
  padding-bottom: 0;
  padding-top: 0;

  &.navbar-expand-lg {
    .navbar-nav {
      .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }
  }

  .navigation-content {
    @include contentWidth(nav);

    .navbar-brand {
      img {
        max-width: 150px;
        min-width: 150px;

        @include breakpoint(sm) {
          max-width: 200px;
          min-width: 200px;
        }
      }
    }

    .navbar-nav {
      margin: 8px 0;

      li {
        &.nav-item {
          margin-bottom: 0;

          .nav-link {
            box-sizing: border-box;
            border-bottom: 2px solid $white;
            color: $port-gore;
            @include font-prop(p);
            font-weight: $font-weight-slightlybold;
            padding: 20px 6px;
            margin: 0 3px;
            text-decoration: none;

            @include breakpoint(xl) {
              padding: 20px;
            }

            &:hover,
            &:focus {
              background-color: $hover-bg;
              border-bottom: 2px solid $taxhawk-primary;
            }
          }

          @keyframes opacity-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes opacity-out {
            0% {
              opacity: 1;
            }
            80% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }

          .dropdown-menu {
            -webkit-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            -moz-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            -ms-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            -o-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);

            max-height: 0;
            min-width: 100%;
            display: block;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 102%;

            &.show {
              max-height: 304px;
              opacity: 1;
              visibility: visible;
              transition:
                max-height 0.25s ease-in,
                opacity 0.1s ease-in;
              min-width: 100%;
              top: 100%;
            }

            &.large-menu {
              &.show {
                max-height: 400px;
              }
            }

            li {
              margin-bottom: 0;

              a {
                color: $port-gore;
                font-size: 1rem;
                font-weight: $font-weight-slightlybold;
                line-height: 26px;
                padding: 0.5rem 1rem;

                &:hover {
                  background: none;
                  color: $taxhawk-primary;
                  text-decoration: underline;
                  text-decoration-color: $taxhawk-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
