const getModal = () => {
  const showModal = (name, id = "") => {
    let moreThanOneFAQ = name.includes(",");
    if (moreThanOneFAQ) {
      name = name.substring(0, name.indexOf(","));
    }
    const modal = document.getElementById(name + "Modal");
    modal.classList.remove("modal-backdrop-hidden");
    modal.children[0].classList.remove("modal-hidden");

    document.body.classList.add("modal-open");

    if (id && document.getElementById(id)) {
      document.getElementById(id).scrollIntoView();
    }
  };
  const closeModal = (name) => {
    let moreThanOneFAQ = name.includes(",");
    if (moreThanOneFAQ) {
      name = name.substring(0, name.indexOf(","));
    }
    const modal = document.getElementById(name + "Modal");
    modal.classList.add("modal-backdrop-hidden");
    modal.children[0].classList.add("modal-hidden");

    document.body.classList.remove("modal-open");
  };
  const showDifferentModal = (currentModal, newModal) => {
    closeModal(currentModal);
    showModal(newModal);
  };

  return {
    showModal,
    closeModal,
    showDifferentModal,
  };
};

export default getModal;
