const appName = process.env.VUE_APP_SITENAME;
const appYear = process.env.VUE_APP_TAX_YEAR;
const currentDateYear = new Date().getFullYear();
const numberReturnsFiled = "63,000,000+";
const freefileParam = "?FREEFILE";
let authSubdomain = process.env.VUE_APP_AUTH_SUBDOMAIN;
let env = process.env.VUE_APP_ENV_NAME;

let appUrl = "https://" + authSubdomain + "." + appName + ".com" + freefileParam;

//test code for nginx-proxy server
if (env === "dev") appUrl = "https://" + authSubdomain + "." + appName + ".localhost:8443" + freefileParam;

let newUrl = appUrl + "&NEWTAX";
let deluxeUrl = appUrl + "&DLX";
let continueUrl = appUrl;
let amendedUrl = appUrl + "&goto_page=106";
let checkUrl = appUrl + "&goto_page=50";
let contactSupportDeluxeUrl = appUrl + "&goto_page=7";
let contactSupportUrl = appUrl + "&goto_page=7";
let extUrl = appUrl + "&NEWTAX&NEWEXT";
let emailUrl = appUrl + "&goto_page=60";
let signInHelpUrl = "https://" + authSubdomain + "." + appName + ".com/ReclaimAccount" + freefileParam;
let creditUrl = appUrl + "&goto_page=547";
let printUrl = appUrl + "&goto_page=100001";

export const constants = {
  amendedUrl: amendedUrl,
  appName: appName,
  appYear: appYear,
  currentDateYear: currentDateYear,
  checkUrl: checkUrl,
  contactSupportDeluxeUrl: contactSupportDeluxeUrl,
  contactSupportUrl: contactSupportUrl,
  continueUrl: continueUrl,
  creditUrl: creditUrl,
  deluxeUrl: deluxeUrl,
  emailUrl: emailUrl,
  extUrl: extUrl,
  newUrl: newUrl,
  signInHelpUrl: signInHelpUrl,
  printUrl: printUrl,
  numberReturnsFiled: numberReturnsFiled,
}
