import getDate from "@/functions/getDate";

const getFreeFileConfigs = async (configs) => {
  const currentDate = getDate();
  const startFreeFile = new Date(configs.startFreeFile);
  const endFreeFile = new Date(configs.currentTaxYear + 1 + "/10/21");

  configs.isFreeFileActive =
    currentDate >= startFreeFile && currentDate < endFreeFile;

  return configs;
};

export default getFreeFileConfigs;
