import Configs from "@/../data/default-configs.json";
import { store } from "@/functions/store";
import getExtensionMessageConfigs from "@/functions/getExtensionMessageConfigs";
import getBannerConfigs from "@/functions/getBannerConfigs";
import getFreeFileConfigs from "@/functions/getFreeFileConfigs";
import createUUID from "@/functions/createUUID";

const getConfigs = async () => {
  let configApi =
    "https://" +
    process.env.VUE_APP_API_SUBDOMAIN +
    "." +
    process.env.VUE_APP_SITENAME +
    ".com" +
    process.env.VUE_APP_MONOSERVICE_PATH +
    "/configs/" +
    process.env.VUE_APP_TAX_YEAR;
  const isTimeTravelTesting = process.env.VUE_APP_TIME_TRAVEL;
  if (
    isTimeTravelTesting === "true" &&
    location.href.includes("date=") &&
    location.href.includes("data=prod")
  ) {
    configApi =
      "https://api." +
      process.env.VUE_APP_SITENAME +
      ".com" +
      process.env.VUE_APP_MONOSERVICE_PATH +
      "/configs/" +
      process.env.VUE_APP_TAX_YEAR;
  }

  let trace = createUUID();

  await fetch(configApi, {
    method: "get",
    headers: {
      "X-Trace": trace,
    },
  })
    .then(async (response) => {
      store.configs = await response.json();
    })
    .catch(() => (store.configs = Configs.data));

  store.configs = await getExtensionMessageConfigs(store.configs);
  store.configs = await getBannerConfigs(store.configs);
  store.configs = await getFreeFileConfigs(store.configs);
};

export default getConfigs;
