<template>
  <nav class="navbar navbar-expand-lg fixed-top">
    <div
      class="container-fluid navigation-content justify-content-normal justify-content-lg-between"
    >
      <div class="d-flex align-items-center">
        <MobileMenuToggle />

        <transition duration="0">
          <router-link
            id="firstTabIndex"
            class="navbar-brand no-outline"
            to="/"
            @click="closeMobileMenu"
          >
            <img src="@/assets/img/logo.svg" alt="Go to FreeTaxUSA home page" />
          </router-link>
        </transition>
      </div>

      <div class="d-flex align-items-center">
        <div
          class="collapse navbar-collapse pb-4 pb-md-0"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                @click="removeFocus"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Pricing & Products
              </a>
              <ul class="dropdown-menu animate expand shadow">
                <li>
                  <router-link class="dropdown-item" to="/pricing"
                    >Pricing</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/software"
                    >Our Free Federal</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/deluxe"
                    >Deluxe</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/pro"
                    >Pro Support</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/audit"
                    >Audit Defense</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/extension"
                    >Tax Extensions</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/prior-year"
                    >Prior Year Returns</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                @click="removeFocus"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Support
              </a>
              <ul class="dropdown-menu animate expand shadow">
                <li>
                  <router-link class="dropdown-item" to="/support"
                    >Customer Support</router-link
                  >
                </li>
                <li>
                  <a class="dropdown-item" :href="constants.signInHelpUrl"
                    >Forgot Username or Password?</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" :href="constants.contactSupportUrl"
                    >My Support Issues</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://community.freetaxusa.com/"
                    target="_blank"
                    >Community Answers & Help</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                @click="removeFocus"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Prior Year
              </a>
              <ul class="dropdown-menu large-menu animate expand shadow">
                <li>
                  <router-link class="dropdown-item" to="/prior-year"
                    >All Years 2016-2022</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/2022"
                    >File 2022 Tax Return</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/2021"
                    >File 2021 Tax Return</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" to="/2020"
                    >File 2020 Tax Return</router-link
                  >
                </li>
                <li>
                  <a class="dropdown-item" :href="constants.printUrl"
                    >View My Prior Year Return(s)</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                @click="removeFocus"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                After You File
              </a>
              <ul class="dropdown-menu animate expand shadow">
                <li>
                  <a class="dropdown-item" :href="constants.checkUrl"
                    >Check E-File Status</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" :href="constants.printUrl"
                    >Print / Download My Return</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isPriorYear" class="d-flex">
        <div class="dropdown">
          <a
            class="btn sign-in dropdown-toggle mb-0"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Sign In
          </a>
          <ul id="year_select" class="dropdown-menu">
			  <li>
				  <a id="year2023" :href="constants.priorYear2023Url"
				  >Sign In to 2023</a
				  >
			  </li>
            <li>
              <a id="year2022" :href="constants.priorYear2022Url"
                >Sign In to 2022</a
              >
            </li>
            <li>
              <a id="year2021" :href="constants.priorYear2021Url"
                >Sign In to 2021</a
              >
            </li>
            <li>
              <a id="year2020" :href="constants.priorYear2020Url"
                >Sign In to 2020</a
              >
            </li>
            <li>
              <a id="year2019" :href="constants.priorYear2019Url"
                >Sign In to 2019</a
              >
            </li>
            <li>
              <a id="year2018" :href="constants.priorYear2018Url"
                >Sign In to 2018</a
              >
            </li>
            <li>
              <a id="year2017" :href="constants.priorYear2017Url"
                >Sign In to 2017</a
              >
            </li>
            <li>
              <a id="year2016" :href="constants.priorYear2016Url"
                >Sign In to 2016</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div
        v-else-if="
          store.configs.afterEqualLaunch &&
          store.configs.displayPriorYearAppMessage
        "
        class="d-flex"
      >
        <div class="dropdown">
          <a
            class="btn sign-in dropdown-toggle mb-0"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Sign In
          </a>
          <ul id="year_select" class="dropdown-menu">
            <li>
              <a :href="continueUrl + '&appYear=2023'">Sign In to 2023</a>
            </li>
            <li>
              <a :href="continueUrl + '&appYear=2022'">Sign In to 2022</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="d-flex">
        <!--		  navButtonABTest-->
        <a id="navButtonABv1" class="d-none btn sign-in-white me-2" :href="constants.newUrl">Start</a>

        <a class="btn sign-in" :href="constants.continueUrl">Sign In</a>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { store } from "@/functions/store";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import MobileMenuToggle from "@/components/MobileMenuToggle";
import { constants } from "@/functions/constants";
import menuMobileToggle from "@/functions/menuHandlerMobile";
import menuToggle from "@/functions/menuHandler";

const { closeMenu, removeFocus } = menuToggle();
const { closeMobileMenu } = menuMobileToggle();
const route = useRoute();
const isPriorYear = ref(false);
const path = ref("");

onMounted(() => {
  window.addEventListener("scroll", closeMenu);
});

watch(route, () => {
  path.value = route.path.split("/").pop();
  isPriorYear.value =
    path.value === "prior-year" ||
    path.value === "2023" ||
    path.value === "2022" ||
    path.value === "2021" ||
    path.value === "2020" ||
    path.value === "2019" ||
    path.value === "2018" ||
    path.value === "2017" ||
    path.value === "2016";
});
</script>

<style lang="scss" scoped>
.navbar {
  background-color: $white;
  min-height: 60px;
  padding-bottom: 0;
  padding-top: 0;

  &.navbar-expand-lg {
    .navbar-nav {
      .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }
  }

  .navigation-content {
    @include contentWidth(nav);

    .navbar-brand {
      img {
        max-width: 150px;
        min-width: 150px;

        @include breakpoint(sm) {
          max-width: 200px;
          min-width: 200px;
        }
      }
    }

    .navbar-nav {
      margin: 8px 0;

      li {
        &.nav-item {
          margin-bottom: 0;

          .nav-link {
            box-sizing: border-box;
            border-bottom: 4px solid $white;
            color: $port-gore;
            @include font-prop(p);
            font-weight: $font-weight-slightlybold;
            padding: 20px 6px;
            margin: 0 3px;
            text-decoration: none;

            @include breakpoint(xl) {
              padding: 20px;
            }

            &:hover,
            &:focus {
              background-color: $hover-bg;
              border-bottom: 4px solid $taxhawk-primary;
            }
          }

          @keyframes opacity-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          @keyframes opacity-out {
            0% {
              opacity: 1;
            }
            80% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }

          .dropdown-menu {
            -webkit-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            -moz-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            -ms-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            -o-transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);
            transition:
              max-height 0.25s ease-out,
              opacity 0.25s cubic-bezier(0.99, 0.04, 0.92, 0.46);

            max-height: 0;
            min-width: 100%;
            display: block;
            overflow: hidden;
            opacity: 0;
            position: absolute;
            top: 102%;
            visibility: hidden;

            &.show {
              max-height: 304px;
              opacity: 1;
              transition:
                max-height 0.25s ease-in,
                opacity 0.1s ease-in;
              min-width: 100%;
              visibility: visible;
            }

            &.large-menu {
              &.show {
                max-height: 400px;
              }
            }

            li {
              margin-bottom: 0;

              a {
                color: $port-gore;
                font-size: 1rem;
                font-weight: $font-weight-slightlybold;
                line-height: 26px;
                padding: 0.5rem 1rem;

                &:hover {
                  background: none;
                  color: $taxhawk-primary;
                  text-decoration: underline;
                  text-decoration-color: $taxhawk-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Sign In prior-year drop down */
.dropdown {
  .dropdown-menu {
    z-index: 35;
    background-color: #fff;
    color: #444;
    position: absolute;
    margin: 0;
    padding: 0;
    border: 1px solid #e2e5e9;
    box-shadow: 0 12px 24px -8px rgba(33, 47, 69, 0.45);
    text-align: left;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;

    &#year_select {
      right: 0;
      left: auto;
      text-align: center;

      a {
        padding: 0 1rem;
      }
    }

    &.show {
      max-height: 330px;
      opacity: 1;
      visibility: visible;
    }

    a {
      display: block;
      height: 40px;
      line-height: 40px;
      padding: 0 8px 0 18px;
      color: #444;
      font-size: 15px;
      text-decoration: none;

      &:hover,
      &.active {
        background-color: #dfdfdf;
        color: #000000;
      }
    }
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}
</style>
