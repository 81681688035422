<template>
  <div class="mobile-menu d-flex flex-column flex-row-auto" id="mobile-menu">
    <div class="mobile-menu-wrapper flex-column-fluid">
      <div
        class="mobile-menu-container mt-4 pb-4"
        data-menu-vertical="1"
        data-menu-scroll="1"
        data-menu-dropdown-timeout="500"
      >
        <ul class="mobile-menu-nav accordion" id="mobileAccordionFreefile">
          <li class="mobile-menu-item accordion-item pt-2 pb-2">
            <a
              class="accordion-link"
              href="https://www.irs.gov/freefile"
              target="_blank"
            >
              <span class="mobile-menu-title"
                ><span class="menu-text">Return to Free File</span></span
              >
            </a>
          </li>
          <li class="mobile-menu-item accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <span class="mobile-menu-title"
                ><span class="menu-text">Support</span></span
              >
            </button>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              data-bs-parent="#mobileAccordionFreefile"
            >
              <div class="accordion-body">
                <router-link
                  class="mobile-menu-link"
                  to="/freefile2023/contact"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Customer Support</span></router-link
                >
                <a
                  class="mobile-menu-link"
                  :href="constants.contactSupportUrl"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">My Support Issues</span></a
                >
              </div>
            </div>
          </li>
          <li class="mobile-menu-item accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <span class="mobile-menu-title"
                ><span class="menu-text">After You File</span></span
              >
            </button>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#mobileAccordionFreefile"
            >
              <div class="accordion-body">
                <a
                  class="mobile-menu-link"
                  :href="constants.checkUrl"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Check E-File Status</span></a
                >
                <router-link
                  class="mobile-menu-link"
                  to="/freefile2023/refund-status"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Where's My Refund</span></router-link
                >
                <a
                  class="mobile-menu-link"
                  :href="constants.printUrl"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Print / Download My Return</span></a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="mobile-menu-overlay"
    id="mobile-menu-overlay"
    @click="toggleMobileMenu"
  ></div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import menuToggle from "@/functions/menuHandlerMobile";
import { useRoute } from "vue-router";
import { constants } from "@/functions/constants-freefile";

const { closeMobileMenu, toggleMobileMenu } = menuToggle();
const route = useRoute();

onMounted(() => {
  window.addEventListener("DOMContentLoaded", closeMobileMenu);
  window.addEventListener("resize", closeMobileMenu);
  document.addEventListener("keydown", (evt) => {
    if (evt.key === "Escape") {
      closeMobileMenu();
    }
  });
  function dataGet() {
    let accordionCollapseItems = document.getElementById(
      "mobileAccordionFreefile",
    ).children;
    for (let i = 0; i < accordionCollapseItems.length; i++) {
      let menuButton = accordionCollapseItems[i].children[0];
      if (!menuButton.classList.value.includes("accordion-link")) {
        menuButton.classList.add("collapsed");
        let menuDiv = accordionCollapseItems[i].children[1];
        menuDiv.classList.remove("show");
      }
    }
  }
  watch(route, () => {
    dataGet();
  });
});
</script>
